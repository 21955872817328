<template>

  <b-card style="max-width: 150rem; margin: auto;">
    <b-overlay
      :show="show"
      rounded="xl"
      :variant="variant"
      :opacity="opacity"
      :blur="blur"
    >
    <b-card-header class="pb-50">
      <b-card-title>List User</b-card-title>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-success"
          class="btn-icon rounded-circle"
          @click="showModal(null)"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>
    </b-card-header>
    <b-card-body>
      <div class="d-flex justify-content-between  flex-wrap">

        <!-- sorting  -->
        <b-form-group
          label="Sort"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="2"
          label-for="sortBySelect"
          class="mr-1 mb-md-0"
        >
          <b-input-group
            size="sm"
          >
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  none
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>

        <!-- filter -->
        <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
        <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                  @input="isEmptyHandle"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="onChangeText"
                    
                  >
                    Search
                  </b-button>
                </b-input-group-append>
              </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>

    <b-table
      striped
      hover
      responsive
      ref="tablea"
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(balance.current)="data">
        {{Number(data.item.balance.current).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}}
      </template>
      <template #cell(balance.total)="data">
        {{Number(data.item.balance.total).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}}
      </template>
      <template #cell(account_renew)="data">
        {{ports.filter(x=>x.uid == data.item._id && x.renew).length}}
      </template>
      <template #cell(total_account)="data">
        {{ports.filter(x=>x.uid == data.item._id).length}}
      </template>
      <template #cell(block)="data">
        <b-badge :variant="status[0][data.item.block]">
          {{status[1][data.item.block]}}
        </b-badge>
      </template>

      <template #cell(status)="data">
        <b-badge :variant="data.item.status == 'Active' ? 'success' : 'warning'">
          {{data.item.status}}
        </b-badge>
      </template>
      <template #cell(day_not_use)="data">
        {{ getDayLeft(data.item) }}
      </template>
      <template #cell(bandwidth.enable)="data">
        <b-badge v-if="data.item.bandwidth.enable" variant="success">Enabled</b-badge>
        <b-badge v-else variant="danger">Disabled</b-badge>
      </template>
      <template #cell(bandwidth)="data">
        Total: {{getBW(data.item).total}} Remaining: {{getBW(data.item).used}}
      </template>
      <template #cell(_id)=data>
        <div class="text-nowrap">
            <b-button
              v-if="data.item.status == 'Pending'"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-success"
              class="btn-icon rounded-circle"
              @click="activeUser(data.item)"
            >
              <feather-icon icon="FlagIcon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              :variant="data.item.block ? 'flat-danger':'flat-success'"
              class="btn-icon rounded-circle"
              @click="blockUser(data.item._id)"
            >
              <feather-icon :icon="data.item.block ? 'LockIcon' : 'UnlockIcon'" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-info"
              class="btn-icon rounded-circle"
              :to="{name: 'users-edit',params: {id: data.item._id}}"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-warning"
              class="btn-icon rounded-circle"
              @click="showModal(data.item)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <!-- <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-danger"
              class="btn-icon rounded-circle"
              @click="deleteUser(data.item._id)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button> -->
        </div>
        <div class="text-nowrap">
          
        </div>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
     </b-overlay>
    <b-modal
      id="modal-add-user"
      ok-title="Add"
      cancel-title="Cancel"
      size="lg"
      title="Add New User"
      @ok="onSubmit"
    >
      <b-form @submit.prevent>
        <b-col cols="12">
          <b-form-group
            label="Full Name"
            label-for="h-full-name"
            label-cols-md="4"
          >
            <b-form-input
              id="h-full-name"
              placeholder="Full Name"
              v-model="form.fullname"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Email"
            label-for="h-email"
            label-cols-md="4"
          >
            <b-form-input
              id="h-email"
              placeholder="Email"
              v-model="form.email"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Facebook"
            label-for="h-facebook"
            label-cols-md="4"
          >
            <b-form-input
              id="h-facebook"
              placeholder="Facebook"
              v-model="form.facebook"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Username"
            label-for="h-user-name"
            label-cols-md="4"
          >
            <b-form-input
              id="h-user-name"
              placeholder="Username"
              v-model="form.username"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Password"
            label-for="h-password"
            label-cols-md="4"
          >
            <b-form-input
              id="h-password"
              placeholder="Password"
              v-model="form.password"
            />
          </b-form-group>
        </b-col>
      </b-form>
    </b-modal>
  </b-card>

</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BOverlay, BCard, BTooltip, BCardHeader, BCardTitle, BCol, BForm
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import RepositoryFactory from '../../../api/RepositoryFactory'
const UserManagersRepository = RepositoryFactory.get('usermanagers')
const PlanManagersRepository = RepositoryFactory.get('plansmanager')
const NPortManagerRepository = RepositoryFactory.get('anport')
const NPlanManagersRepository = RepositoryFactory.get('anplan')
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BCard,
    BTooltip,
    BCardHeader,
    BCardTitle,
    BCol,
    BForm
  },
  data() {
    return {
      show:true,
      variant: 'dark',
      opacity: 0.85,
      blur: '2px',
      perPage: 20,
      pageOptions: [5, 10, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      form:{
        _id: '',
        fullname: '',
        email: '',
        facebook: '',
        username: '',
        password: '',
      },
      fields: [
        //'STT',
        { key: 'username', label: 'User', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'name', label: 'Full Name', sortable: true },
        { key: 'facebook', label: 'ID Facebook', sortable: true },
        { key: 'balance.current', label: 'Current Balance', sortable: true },
        { key: 'balance.total', label: 'Total Balance', sortable: true },

        'day_not_use',
        'bandwidth',
        {name: 'zone', key: 'bandwidth.enable', label: 'Zone Status', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: '_id', label: 'Action' },
      ],
      /* eslint-disable global-require */
      items:[],
      base_items: [],
      /* eslint-disable global-require */
      status: [
        {
          false: 'light-success', true: 'light-danger',
        },
        {
          true: 'Blocked', false: 'Active',
        },
      ],
      ports: [],
      nport: [],
      nplans: []
    }
  },
  created(){
    this.show = true
    UserManagersRepository.fetch().then(rs=>{
      this.items = rs.data.data
      this.base_items = rs.data.data
      this.totalRows = this.items.length
      PlanManagersRepository.fetchID().then(rs=>{
        this.ports = rs.data.data
        this.show = false
      })
      NPortManagerRepository.fetch().then(rs=>{
        this.nport = rs.data.data
      })
      NPlanManagersRepository.fetch().then(rs=>{
        this.nplans = rs.data.data
      })

    }).catch(e=>{

    })
  },
  directives: {
    Ripple,
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    onChangeText(){

      this.items = this.base_items.filter(x=>(x.username.includes(this.filter) || x.email.includes(this.filter)))
      this.totalRows = this.items.length
    },

    isEmptyHandle(){
      if(this.filter == ""){
        //this.$refs.tablea.filter = this.filter
        this.items = this.base_items
        this.totalRows = this.items.length
      }
        
    },
    getPort(uid){
      //console.log(uid)
      return this.nport.filter(x=>x.uid == uid && x.status).length
    },
    getDayLeft(uid){
      const plan = this.nplans.filter(x=>x.uid == uid._id).sort()
      
      if(plan.length <= 0)
      {
        return "No Plan"
      }
        
      
      let active_plan = plan.filter(x=>x.active)
      if(active_plan.length > 0)
        return "Running"
      const last_plan = plan.slice(-1).pop()
      return  parseInt((new Date(last_plan.date_expired) - Date.now()) / (1000 * 60 * 60 * 24), 10); 
    },
    getBW(uid){
      return {
        total: this.formatBytes(uid.bandwidth.total),
        used: this.formatBytes(uid.bandwidth.remainning)
      }
      let f = this.nplans.filter(x=>x.uid == uid)
      if(f.length > 0){
        let total = f.map(x=>x.bandwidth).reduce((a,b)=>a+b)
        let used = f.map(x=>x.current_bandwidth).reduce((a,b)=>a+b)
        let rem = total - used
        return {
          total: this.formatBytes(total),
          used: this.formatBytes(rem)
        }
      }else{
        return {
          total: 0,
          used: 0
        }
      }
    
      
    },
    onSubmit(event){
      event.preventDefault()
      if(this.form._id == ''){
        UserManagersRepository.addUser(this.form).then(rs=>{
          if(rs.data.success){
            this.items.push(rs.data.data)
            this.$bvModal.hide('modal-add-user')
          }else{
            this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Notification',
                    icon: 'BellIcon',
                    text: rs.data.errMsg,
                    variant: 'danger',
                  },
              })
          } 
        })
      }else{
        UserManagersRepository.editUser(this.form._id, this.form).then(rs=>{
          if(rs.data.success){
            this.items.forEach(x => {
              if(x._id == this.form._id){
                x.name = this.form.fullname
                x.facebook = this.form.facebook
                x.email = this.form.email
              }
            });
            this.$bvModal.hide('modal-add-user')
          }else{
            this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Notification',
                    icon: 'BellIcon',
                    text: rs.data.errMsg,
                    variant: 'danger',
                  },
              })
          } 
        })
      }
    },
    blockUser(id){
      UserManagersRepository.blockUser(id).then(rs=>{
        if(rs.data.success){
          let item = this.items.find(x=>x._id == id)
          if(item)
            item.block = !item.block
        }else{
          this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: rs.data.errMsg,
            variant: 'danger',
          },
      })
        }
      }).catch(e=>{

      })
    },

    activeUser(id){
      this.$bvModal
        .msgBoxConfirm( `Active user ${id.username}?`, {
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            UserManagersRepository.activeUser(id._id).then(rs=>{
              id.status = 'Active'
              let index = this.items.find(x=>x._id == id._id)
              this.items[index] = id
            }).catch(e=>{
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: rs.data.errMsg,
                  variant: 'danger',
                },
            })
            })
          }
        })
    },
    editUser(id){
      this.$router.to('/'+id)
    },
    deleteUser(id){
      this.$bvModal
        .msgBoxConfirm( `Delete user ${id.username}?`, {
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            UserManagersRepository.deleteUsers(id).then(rs=>{
              this.items = this.items.filter(x=>x._id != id)
            }).catch(e=>{
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: rs.data.errMsg,
                  variant: 'danger',
                },
            })
            })
          }
        })
    },
    showModal(data){
      console.log(data)
      if(!data){
        this.form = {
          _id: '',
          fullname: '',
          email: '',
          facebook: '',
          username: '',
          password: '',
        }
        this.$bvModal.show('modal-add-user')
      }else{
        this.form = {
          _id: data._id,
          fullname: data.name,
          email: data.email,
          facebook: data.facebook,
          username: data.username,
          password: '',
        }
        this.$bvModal.show('modal-add-user')

      }
     
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    formatBytes(bytes) {
        var marker = 1000; // Change to 1000 if required
        var decimal = 2; // Change as required
        var kiloBytes = marker; // One Kilobyte is 1024 bytes
        var megaBytes = marker * marker; // One MB is 1024 KB
        var gigaBytes = marker * marker * marker; // One GB is 1024 MB
        var teraBytes = marker * marker * marker * marker; // One TB is 1024 GB
        // return bytes if less than a KB
      if(bytes > 0){
        if(bytes < kiloBytes) return bytes + " Bytes";
        // return KB if less than a MB
        else if(bytes < megaBytes) return (bytes / kiloBytes).toFixed(decimal) + " KB";
        // return MB if less than a GB  ``
        else if(bytes < gigaBytes) return(bytes / megaBytes).toFixed(decimal) + " MB";
        // return GB if less than a TB
        else if(bytes < teraBytes) return (bytes / gigaBytes).toFixed(decimal) + " GB";
        
        else return (bytes / teraBytes).toFixed(decimal) + " TB";
      }else{
        bytes = bytes * -1

        if(bytes < kiloBytes) return bytes*-1 + " Bytes";
        // return KB if less than a MB
        else if(bytes < megaBytes) return(bytes / kiloBytes).toFixed(decimal)*-1 + " KB";
        // return MB if less than a GB  ``
        else if(bytes < gigaBytes) return(bytes / megaBytes).toFixed(decimal)*-1 + " MB";
        // return GB if less than a TB
        else if(bytes < teraBytes) return (bytes / gigaBytes).toFixed(decimal)*-1 + " GB";
        
        else return (bytes / teraBytes).toFixed(decimal)*-1 + " TB";

      } 
    }
  },
}
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/vue-select.scss';
.dark-layout {
  div ::v-deep .card .card-body {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>